<template>
  <router-link class="jnrLink" :to="'/jnr/' + item.parcourShortId">
    <div class="card mb-3" style="height: 100%">
      <div class="row g-0">
        <div class="col-md-4 mt-4">
          <img :src="'https://www.timolia.de/media/minecraft/1.16.5/' + item.icon.toLowerCase() + '.png'" class="jnrIcon rounded-start" :alt="item.icon">
          <p class="card-text">{{item.parcourShortId}}</p>
        </div>
        <div class="col-md-8">
          <div class="card-body">
            <h5 class="card-title">{{item.title}}</h5>
            <p class="card-text">
              <span><img :src="'https://www.timolia.de/media/minecraft/1.16.5/diamond.png'" class="ratingIcon" alt="diamond">{{item.creativity || '-'}}</span>
              <span><img :src="'https://www.timolia.de/media/minecraft/1.16.5/firework_rocket.png'" class="ratingIcon" alt="firework_rocket">{{item.jumps || '-'}}</span>
              <span><img :src="'https://www.timolia.de/media/minecraft/1.16.5/orange_tulip.png'" class="ratingIcon" alt="orange_tulip">{{item.optics || '-'}}</span>
            </p>
            <p class="card-text"><small class="text-muted">{{formatDate(item.released)}} von {{item.username}}</small></p>
          </div>
        </div>
      </div>
    </div>
  </router-link>
</template>

<script>
import Helper from "@/utils/helper";

export default {
  name: "SearchItem",
  props: ['item'],
  methods: {
    formatDate(timestamp) {
      return Helper.formatDate(timestamp);
    },
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped>
.jnrIcon {
  height: 64px;
  width: 64px;
}

.ratingIcon {
  height: 24px;
  width: 24px;
}

@media (prefers-color-scheme: dark) {
  .jnrLink {
    color: #f8f9fa;
    background-color: transparent;
    border: 0;
  }
}

.jnrLink {
    font-weight: 400;
    text-align: inherit;
    text-decoration: none;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}

.jnrLink:hover .card {
  background-color: #c6862b;
}

.jnrLink:hover .text-muted {
  color: white !important;
}
</style>

<template>
  <div class="container">
    <div class="row text-center justify-content-center">
      <h1>Suche</h1>
      <div class="col-lg-6 col-md-12">
        <div class="row justify-content-center mb-3" id="imageContainer">
          <div class="selectMode col-4" v-bind:class="mode === 'jnr' ? 'selected' : ''" v-on:click="updateMode('jnr')">
            <img src="../assets/firework.png" height="64" alt="Jump'n'Run"/>
            <p>JnR</p>
          </div>
          <div class="selectMode col-4" v-bind:class="mode === 'player' ? 'selected' : ''"
               v-on:click="updateMode('player')">
            <img src="../assets/skull_mcexpertde.png" height="70" alt="Spieler"/>
            <p>Spieler</p>
          </div>
        </div>

        <div class="input-group mb-3">
          <input type="text" class="form-control " placeholder="" aria-label="Suchen"
                 aria-describedby="Suche" @input="search">
          <button class="btn btn-outline-warning" type="button" id="search" v-bind:disabled="buttonState" v-on:click="sendRequest()">Suchen</button>
        </div>

        <div v-if="!loaded && !empty" class="mt-5 text-center">
          <Loader></Loader>
        </div>
        <div v-if="empty" class="mt-5 text-center">
          <h1>Es wurden keine Suchergebnisse gefunden!</h1>
        </div>

        <div v-for="item in searchItems" v-bind:key="item.parcourId">
          <SearchItem :item="item"></SearchItem>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from "debounce";
import SearchItem from "../components/SearchItem";
import Loader from "../components/Loader";
import {env} from "@/environment/env";

export default {
  name: "Search",
  components: {SearchItem, Loader},
  data() {
    return {
      searchVar: '',
      searchItems: [],
      allowNextRequest: 0,
      buttonState: false,
      mode: 'jnr',
      loaded: true,
      empty: false
    }
  },
  methods: {
    updateMode(mode) {
      this.mode = mode;
      this.sendRequest();
    },
    search: debounce(function (e) {
      this.searchVar = e.target.value.toUpperCase();
      this.sendRequest(e.target.value);
    }, 450),
    sendRequest() {
      this.allowNextRequest = (new Date).getTime() + 450;
      console.log('search!')
      if ((new Date).getTime() <= this.allowNextRequest) {
        if (this.searchVar) {
          this.loaded = false;
          this.empty = false;
          const route = env.TIMOLIA_API_URL + '/jumpworld/search/' + this.mode + '/' + this.searchVar;
          this.buttonState = true;
          this.enableButton(440);
          fetch(route, { method: 'GET', redirect: 'follow'})
            .then(response => response.json())
            .then((data) => {
              this.searchItems = data.results;
              this.loaded = true;
              if (data.results.length === 0) {
                this.empty = true;
              }
            }).catch(() => {
            this.loaded = true;
          })
        } else {
          this.searchItems = {};
        }
      }
    },
    enableButton(timeout) {
      setTimeout(function () {
        this.buttonState = false;
      }.bind(this), timeout)
    }
  }
}
</script>

<style scoped>
.selected {
  background-color: #f39c12;
}

#imageContainer {
  cursor: pointer;
  margin-left: 0;
  margin-right: 0;
}

.selectMode p {
  margin-bottom: 0;
}
</style>
